<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>审核管理</a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="onBackList">未交付列表</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <section class="info-container">
      <a-row>
        <a-col><div class="info-title">基本信息</div></a-col>
      </a-row>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单编号">{{ info.orderSn }}</a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="创建时间">
              {{ tsFormat(info.createTime) }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单状态">{{ info.orderStatus }}</a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="总装运体积">
              {{ info.totalVolume }}m³
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商品总重量">
              {{ info.totalWeight }}kg
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="last-info-container">
      <a-row>
        <a-col><div class="info-title">子订单详情</div></a-col>
      </a-row>
      <section v-for="(item, index) in orderList" :key="index">
        <div class="info">
          <a-row class="row">
            <a-col :span="21">
              <a-row>
                <a-col :span="8" class="col">
                  订单编号：{{ item.procureOrderPartVO.orderSn }}
                </a-col>
                <a-col :span="8" class="col">
                  创建时间：{{ tsFormat(item.procureOrderPartVO.createTime) }}
                </a-col>
                <a-col :span="8" class="col">
                  订单状态：{{ item.procureOrderPartVO.orderStatus }}
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8" class="col">
                  收货人姓名：{{ item.procureOrderPartVO.consigneeName }}
                </a-col>
                <a-col :span="8" class="col">
                  收货人电话：{{ item.procureOrderPartVO.consigneeTel }}
                </a-col>
                <a-col :span="8" class="col">
                  收货地址：
                  <div>
                    {{ item.procureOrderPartVO.consigneeFullDetailAddress }}
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col
              :span="3"
              @click="onOrderDetail(item.procureOrderPartVO.orderSn)"
            >
              <a>查看订单详情</a>
            </a-col>
          </a-row>
        </div>
        <div v-if="item.procureOrderPartVO.issueNum" class="title">
          {{ item.procureOrderPartVO.issueNum }}
        </div>
        <a-table
          class="commodity-table"
          :data-source="item.skuDetailVOList"
          :columns="tableColumns"
          :loading="loading"
          row-key="skuId"
          :pagination="false"
        >
          <div
            slot="skuImages"
            v-viewer
            slot-scope="text, record"
            class="img-container"
          >
            <div
              v-if="record.fileList && record.fileList.length > 0"
              class="img-container"
            >
              <img
                v-if="!record.fileList[0].thumbUrl"
                :src="imageBaseUrl + record.fileList[0].fileUrl"
                :data-source="imageBaseUrl + record.fileList[0].fileUrl"
                alt="产品图片"
                class="img-sku"
              />
              <img
                v-else
                :src="imageBaseUrl + record.fileList[0].thumbUrl"
                :data-source="imageBaseUrl + record.fileList[0].fileUrl"
                alt="产品图片"
                class="img-sku"
              />
            </div>
          </div>
        </a-table>
      </section>
    </section>
    <div class="examine-container">
      <div class="button-container">
        <a-popconfirm
          title="确认撤销拼单?"
          ok-text="确定"
          cancel-text="取消"
          placement="topRight"
          @confirm="onCancelSpelling(info.orderSn)"
        >
          <a-button
            v-if="info.orderStatus === '待发货' && !isShowProduct"
            class="button-item"
          >
            撤销拼单
          </a-button>
        </a-popconfirm>
        <a-button
          v-if="
            info.orderStatus === '已支付' ||
              info.orderStatus === '待发货' ||
              info.orderStatus === '部分发货'
          "
          type="primary"
          class="button-item"
          @click="onDeliver"
        >
          发货
        </a-button>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import {
  onImportProduct,
  patchOrderDetail,
  revokeSpelling
} from "@/services/OrderService";
import { tsFormat } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";

const tableColumns = [
  {
    title: "商品编码",
    dataIndex: "skuCode"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "20%"
  },
  {
    title: "商品图",
    dataIndex: "skuImages",
    scopedSlots: { customRender: "skuImages" }
  },
  {
    title: "商品数量",
    dataIndex: "purchaseQuantity"
  },
  {
    title: "装运体积（m³）",
    dataIndex: "skuVolume"
  },
  {
    title: "商品总重量（kg）",
    dataIndex: "skuWeight"
  }
];
export default {
  data() {
    return {
      tsFormat,
      imageBaseUrl,
      orderSn: this.$route.params.orderSn,
      info: {},
      orderList: [],
      tableColumns,
      loading: false,
      isShowProduct: false,
      isShowRevocation: false
    };
  },
  mounted() {
    this.onDetail();
  },
  methods: {
    onBackList() {
      this.$router.push({ name: "UndeliveredOrder" });
    },
    onDeliver() {
      this.$router.push({ name: "DeliverGoods" });
    },
    onDetail() {
      const factoryId = localStorage.getItem("factoryId");
      patchOrderDetail(factoryId, this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.info = data;
          const childOrderDetailVOList = data.childOrderDetailVOList;
          for (const item of childOrderDetailVOList) {
            if (item.procureOrderPartVO.importedFlag) {
              this.isShowProduct = false;
              this.isShowRevocation = false;
              break;
            }
          }
          this.orderList = childOrderDetailVOList;
        }
      });
    },
    onOrderDetail(orderSn) {
      this.$router.push({
        name: "UndeliveredDetail",
        params: { procureOrderSn: orderSn }
      });
    },
    onImport() {
      const params = {
        orderSn: this.orderSn,
        status: "待生产"
      };
      onImportProduct(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.onDetail();
          this.$message.success("导入成功");
        }
      });
    },
    // 撤销拼单
    onCancelSpelling(orderSn) {
      revokeSpelling(orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("撤销成功");
          this.$router.replace({ name: "UndeliveredOrder" });
        }
      });
    }
  }
};
</script>

<style scoped>
.info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 10px 20px;
  background-color: white;
}
.info-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
.button-container {
  display: flex;
}
.button-item {
  margin-right: 15px;
}
.last-info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 80px 20px;
  background-color: white;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
.img-container {
  width: 100px;
  height: 100px;
}
.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.commodity-table {
  margin-bottom: 20px;
}
.title {
  box-sizing: border-box;
  padding: 12px 20px;
  background-color: #ecf0fe;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}
.info {
  background-color: #f1f6fe;
}
.row {
  display: flex;
  align-items: center;
}
.col {
  box-sizing: border-box;
  padding: 10px;
  display: flex;
}
</style>
